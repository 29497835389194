import './index.module.scss';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import ContactForm from '../../../../components/contact-form/contact-form';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import ContentInner from '../../../../components/content-inner/content-inner';
/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Header />
      <BannerInner
        caption={
          <h2 className="x-large-heading color-white">Portfolio Management</h2>
        }
      />
      <ContentInner
        heading={'Portfolio Management'}
        content={
          <p>
            {' '}
            The management of one’s investments is but one spoke in the wheel of
            one’s overall financial management. Before making any investments,
            we will seek out as much information as possible from the client,
            such as retirement goals, tax planning, significant expenses and
            liquidity needs to come. One we have that big picture, then we will
            build an appropriate portfolio for that person alone.
            <br /> <br />
            The management of an individual or family's investments is but one
            spoke in the wheel of complexity that shapes that clients overall
            financial wellbeing. Before any investments are considered, the
            advisors at DGM spend a considerable amount of time analyzing both
            the quantitative and qualitative aspects of a new client's
            requirements. This includes proprietary software that, through a
            series of engagements with the client, will produce a risk score
            unique to that individual. This score helps our clients visualize
            their feelings and “hunches” and provides a baseline to support
            portfolio construction. At the same time we discuss the clients
            anticipated Life Path, as seen from their eyes and work to identify
            the most important items, items that are required but not special
            and most significantly, items that the client may have overlooked.
            It’s only after this intake process has been completed that we’re
            able to really begin the process of portfolio construction.
            <br /> <br />
            Having said that, there are certain basic tenets that we live by
            across all portfolios. We try to minimize tax costs wherever
            possible; some of that comes from overweighting investments like
            ETFs, which we consider “tax-friendly”, over mutual funds, which
            generally are not (you have no control over the taxable
            distributions they make). We are not beholden to any one strategy or
            asset class, so as the market conditions change, so do we. We will
            diversify amongst several asset classes, from equities to fixed
            income, alternative investments to commodities, depending on the
            market cycle. And everything is fee-based only; we charge no
            commissions so everything we do is in the context of growing your
            assets.
            <br /> <br />
            Because of our structure and relationships, we have the ability to
            use third-party managers as well as trusted partner solutions as we
            design portfolios. Portfolios may be constructed using individual
            securities, exchange-traded funds (ETFs), separately managed
            accounts and/or limited partnerships when appropriate. We manage
            your portfolio daily and will review it with you periodically as a
            part of the ongoing process. If there is to be any significant
            change in direction, we’ll make sure you know all about it.
          </p>
        }
      />
      <ContactForm />
      <Footer />
    </Layout>
  );
}

export default Index;
